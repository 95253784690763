import { Controller } from "@hotwired/stimulus"

import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
hljs.registerLanguage('javascript', javascript);

import trackingPixel from '../data/trackingPixel.js'

export default class extends Controller {
  
  static targets = ['code']


  initialize() {
    this.album = ''
    this.admin_url = document.getElementById("shopify-app-init").dataset.shopOrigin;
    console.log(this.admin_url)
  }
  
  connect() {
    
    this.renderCode();
  }

  parseCode() {
    let parse = trackingPixel.replace(/XXX/g, this.album);
    return hljs.highlight(parse, {language: 'javascript'}).code;
  }


  renderCode() {
    this.codeTarget.textContent = this.parseCode();
  }


  updateAlbum(e) {
    this.album = e.target.value;
    console.log(this.album)
    this.renderCode();
  }


  onCopy(e) {

    //if (this.album === '') return false;

    //navigator.clipboard.writeText(this.parseCode());

    const url = `https://${this.admin_url}/admin/settings/checkout`;

    window.open(url, '_blank')

};

}
