
export default String.raw`<!-- Start Photoslurp Embed Code -->
{% if first_time_accessed %}
<script type="text/javascript">
var patt = /\.*\,\d+$/;
var photoSlurpTrackingSettings = {
  products: {
      {% for line_item in line_items %}
      '{{ line_item.sku | strip_html | strip_newlines | escape |
replace: '&amp;', 'and' | replace: '&#38;', 'and' | replace: "..", ". " | replace: "
", " " | replace: "â€ ̃", "&#39;" | replace: "â€TM", "&#39;" | replace: "&#8216;",
"&#39;" | replace: "&#8217;", "&#39;" | replace: "&#8217;", "&#39;" | replace:
"Ã¢â‚¬â„¢", "&#39;" | replace: "Ã¢â‚¬Å“", "&#39;" | replace: "Ã¢â‚¬Ëœ", "&#39;" |
replace: "Ã‚Â ́", "&#39;" | replace: "â€œ", "&#34;" | replace: "â€•", "&#34;" |
replace: "&#8211;", "-" | replace: "Ã¢â‚¬â€œ", "-" | replace: "Ã¢â‚¬â€•", "-" |
replace: "â€“", "&mdash;" | replace: "â€”", "&mdash;" | replace: "%", "&#37;" |
replace: "Ã‚Â©", "&copy;" | replace: "Ã‚Â®", "&reg;" | replace: "Ã¢â€žÂ¢",
"&trade;" | replace: "Ã‚Â£", "&pound;" | replace: "Ã ̄Â¿Â-", "&#42;" | replace:
"Ã¢â‚¬Â¢", "&#42;" | replace: "Ã¢â‚¬Â•", "&#39;" | replace: "&#233;", "e" |
replace: "Ã©", "e" | replace: "Ã ", "a" | replace: "Ã3", "o" | replace: "Ãa", "e" |
replace: "ÃƒËœ", "O" | replace: "&#8482;", "" | replace: "&#174;", ""}}': {
      count: {{ line_item.quantity }},
      price: patt.test('{{ line_item.price || money_without_currency }}') ? '{{ line_item.price || money_without_currency }}'.replace(/\./g,'').replace(',', '.') : '{{ line_item.price || money_without_currency }}'.replace(/,/g, ''),
      currency: '{{ shop.currency }}'
      },
  {% endfor %}
  },
  orderId: '{{ order.order_number }}',
  albumId: XXX };
  (function() {
      var d = document,
      h = d.getElementsByTagName('head')[0],
      s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = '//static.photoslurp.com/widget/v3/tracking_notify.js';
      h.appendChild(s);
  }());
</script>
{% endif %}
<!-- End Photoslurp Embed Code -->`
